import { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { backendURL } from '../utils/constants';
import { useAuth } from '../context/AuthContext';
import { useRoom } from '../context/RoomContext';

export default function RootComponent() {
  // const [ room, setRoom ] = useState('');
  const [ msg, setMsg ] = useState('');
  const [ disabled, setDisabled ] = useState(true);
  const [ auth ] = useAuth();
  const [ room, updateRoom ] = useRoom();

  useEffect(() => {
    // console.log(auth !== '', room.trim() !== '', , 's');
    setDisabled(!(auth && room.trim() !== ''));
  }, [ auth, room ]);

  const handleCreateRoom = async (e) => {
    e.preventDefault();
    let response = await fetch(backendURL + '/rooms/create', {
      method: 'POST',
      body: JSON.stringify({ roomName: room }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `bearer ${auth}`
      },
    });
    response = await response.json();
    if (response.sid) {
      alert('Room created');
      // updateRoom('');
      setMsg(response);
      localStorage.setItem('roomName', room);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 150
          // height: '500px'
        }}>
        <Typography style={{ fontSize: 36, textDecoration: 'underline' }}>
          Create Room
        </Typography>
      </Grid>
      <Grid item xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 50
          // height: '500px'
        }}
      >
        <FormGroup>
          <FormControl>
            <TextField
              value={room}
              onChange={e => updateRoom(e.target.value)}
              label='Room name'
              variant='outlined'
            />
          </FormControl>
          <FormControl>
            <Button
              onClick={handleCreateRoom}
              disabled={disabled}
              style={{ marginTop: 25 }}
              variant='contained'
            >
              Create
            </Button>
          </FormControl>
          {msg !== '' && <Typography>{JSON.stringify(msg)}</Typography>}
        </FormGroup>
      </Grid>
    </Grid>
  );
};