import { createContext, useContext, useState } from 'react';

const RoomContext = createContext();

function RoomProvider(props) {
  const [room, setRoom] = useState('');

  function updateRoom(roomName) {
    setRoom(roomName);
  }

  return <RoomContext.Provider value={[room, updateRoom]} {...props}></RoomContext.Provider>
};

function useRoom() {
  const context = useContext(RoomContext);
  if(!context) throw new Error('Not inside the Provider')
  return context;
};

export { RoomProvider, useRoom };