import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useAuth } from '../context/AuthContext';

export default function Header() {
  const [ auth, updateAuth ] = useAuth();
  const token = localStorage.getItem('token');
  const [isAuth, setIsAuth] = useState(token ? true : false);

  useEffect(() => {
    updateAuth(token);
    setIsAuth(auth ? true : false);
  }, [ auth ]);

  const handleLogout = () => {
    setIsAuth(false);
    localStorage.clear();
    updateAuth(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar>
          <Typography variant='h6' component='div' sx={{ marginRight: 5 }}>
            <Link to='/' style={{ color: 'white', textDecoration: 'none' }}>
              Home
            </Link>
          </Typography>
          {!isAuth ? (
              <Typography variant='h6' component='div' sx={{ marginRight: 5 }}>
                <Link to='/login' style={{ color: 'white', textDecoration: 'none' }}>
                  Login
                </Link>
              </Typography>
            ) : (
              <>
                <Typography
                  variant='h6'
                  component='div'
                  sx={{ marginRight: 5, cursor: 'pointer' }}
                  onClick={() => handleLogout()}
                >
                  Logout
                </Typography>
                <Typography
                  variant='h6'
                  component='div'
                  sx={{ marginRight: 5, cursor: 'pointer' }}
                >
                  <Link to='/create-room' style={{ color: 'white', textDecoration: 'none' }}>
                    Create Room
                  </Link>
                </Typography>
                <Typography
                  variant='h6'
                  component='div'
                  sx={{ marginRight: 5, cursor: 'pointer' }}
                >
                  <Link to='/join-room' style={{ color: 'white', textDecoration: 'none' }}>
                    Join Room
                  </Link>
                </Typography>
              </>
            )
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
};