import { useEffect, useState } from 'react';
import { connect, createLocalTracks, createLocalVideoTrack } from 'twilio-video';
import Button from '@mui/material/Button';

import { backendURL } from '../utils/constants';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

function RootComponent() {
  const username = localStorage.getItem('username');
  // const roomName = localStorage.getItem('roomName');
  const [roomName, setRoomName] = useState('');
  // const [ username, setUsername ] = useState('');
  // const [ roomName, setRoomName ] = useState('');
  const [ connected, setConnected ] = useState(false);
  const [ buttonDisabled, setButtonDisabled ] = useState(true);
  const [ buttonText, setButtonText ] = useState('Join Room');
  const [ countText, setCountText ] = useState('');
  let room;
  const container = document.getElementById('container');

  useEffect(() => {
    createLocalVideoTrack().then(track => {
      const localMediaContainer = document.getElementById('local').firstChild;
      localMediaContainer.appendChild(track.attach());
    });
  }, []);

  useEffect(() => {
    if (roomName.trim() === '') {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [roomName]);

  const updateParticipantCount = size => {
    if (!connected) {
      setCountText('Disconnected');
    } else {
      setCountText((size + 1) + ' participants online.');
    }
  };

  const trackSubscribed = (div, track) => {
    div.appendChild(track.attach());
  };

  const trackUnsubscribed = track => {
    track.detach().forEach(element => element.remove());
  };

  const participantConnected = participant => {
    console.log('participant', participant);
    const participantDiv = document.createElement('div');
    participantDiv.setAttribute('id', participant.sid);
    participantDiv.setAttribute('class', 'participant');

    const tracksDiv = document.createElement('div');
    participantDiv.appendChild(tracksDiv);

    const labelDiv = document.createElement('div');
    labelDiv.innerHTML = participant.identity;
    participantDiv.appendChild(labelDiv);

    container.appendChild(participantDiv);

    participant.tracks.forEach(publication => {
        if (publication.isSubscribed)
            trackSubscribed(tracksDiv, publication.track);
    });
    participant.on('trackSubscribed', track => trackSubscribed(tracksDiv, track));
    participant.on('trackUnsubscribed', trackUnsubscribed);

    updateParticipantCount();
  };

  const participantDisconnected = participant => {
    document.getElementById(participant.sid).remove();
    updateParticipantCount();
  };

  const connectVideo = () => {
    const endpoint = '/rooms/login';
    let promise = new Promise((resolve, reject) => {
      fetch(backendURL + endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, roomName })
      }).then(res => res.json()).then(data => {
        return createLocalTracks({
          audio: true,
          video: { width: 640 }
        }).then(localTracks => {
          return connect(data.token, {
            room: roomName,
            tracks: localTracks
          });
        })
      }).then(_room => {
        room = _room;
        console.log(`Connected to Room: ${room}`);
        console.log(room.participants);
        room.participants.forEach(participantConnected);
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
        setConnected(true);
        updateParticipantCount(room.participants.size);
        resolve();
      }).catch(() => {
          reject();
      });
    });
    return promise;
  };

  const disconnect = () => {
    room.disconnect();
    while (container.lastChild.id !== 'local') {
      container.removeChild(container.lastChild);
    }
    setButtonText('Join call');
    setConnected(false);
    updateParticipantCount();
  };

  const handleJoin = e => {
    e.preventDefault();
    if (!connected) {
      if (username.trim() === '') {
        alert('Enter your name before connecting');
        return;
      }
      setButtonText('Connecting....');
      setButtonDisabled(true);
      connectVideo().then(() => {
        console.log('connected');
        setButtonText('Leave call');
        setButtonDisabled(false);
      }).catch(() => {
        alert('Connection failed. Is the backend running?');
        setButtonText('Join call');
        setButtonDisabled(false);   
      });
    } else {
      disconnect();
      setButtonText('Join call');
      setConnected(false);
    }
  };

  return (
    <>
      <h1 style={{ paddingLeft: 20 }}>Twilio Video Conference</h1>
      {/* <form>
        <label>Username: </label>
        <input
          type='text'
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <label>Room name: </label>
        <input
          type='text'
          value={roomName}
          onChange={e => setRoomName(e.target.value)}
        />
      </form> */}
      <FormGroup style={{ width: '20%', paddingLeft: 20 }}>
        <FormControl>
          <TextField
            value={room}
            onChange={e => setRoomName(e.target.value)}
            label='Room name'
            variant='outlined'
          />
        </FormControl>
        <FormControl>
          <Button
            onClick={handleJoin}
            disabled={buttonDisabled}
            variant='contained'
          >
            {buttonText}
          </Button>
        </FormControl>
      </FormGroup>
      <p>{countText}</p>
      <div id='container' className='container' style={{ paddingLeft: 20 }}>
        <div id='local' className='participant'>
          <div></div>
          <div>Me</div>
        </div>
      </div>
      <div id='remote'></div>
    </>
  );
}

export default RootComponent;
