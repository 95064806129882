import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { backendURL } from '../utils/constants';
import { useAuth } from '../context/AuthContext';

function LoginComponent() {
  const [username, setUsername] = useState('');
  const [ auth, updateAuth ] = useAuth();
  // const token = localStorage.getItem('token');
  const [loginDisabled, setLoginDisabled] = useState(true);

  useEffect(() => {
    setLoginDisabled(auth ? true : false);
  }, [ auth ]);


  const handleLogin = async (e) => {
    e.preventDefault();
    let response = await fetch(backendURL + '/user/login', {
      method: 'POST',
      body: JSON.stringify({ username }),
      headers: { 'Content-Type': 'application/json' },
    });
    response = await response.json();
    const { token } = response;
    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem('username', username);
      updateAuth(token);
      setLoginDisabled(true);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 150
          // height: '500px'
        }}>
        <Typography style={{ fontSize: 36, textDecoration: 'underline' }}>
          Login
        </Typography>
      </Grid>
      <Grid item xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 50
          // height: '500px'
        }}
      >
        <FormGroup>
          <FormControl>
            <TextField
              value={username}
              onChange={e => setUsername(e.target.value)}
              label='Username'
              variant='outlined'
            />
          </FormControl>
          <FormControl>
            <Button
              onClick={handleLogin}
              disabled={loginDisabled}
              style={{ marginTop: 25 }}
              variant='contained'
            >
              Login
            </Button>
          </FormControl>
        </FormGroup>
        </Grid>
    </Grid>
  );
};

export default LoginComponent;