import Typography from '@mui/material/Typography';

export default function HomePage() {
  return <Typography
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 500,
      fontSize: 36
    }}
  >
    Twilio POC
  </Typography>
}