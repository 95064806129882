import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Header from "./components/Header";
import HomePage from "./components/HomePage";
import RootComponent from "./components/RootComponent";
import HealthCheck from './components/HealthCheck';
import LoginComponent from "./components/LoginComponent";
import RoomComponent from "./components/RoomComponent";

import { AuthProvider } from './context/AuthContext';
import { RoomProvider } from './context/RoomContext';

import './App.css';

function App() {
  return (
    <AuthProvider>
      <RoomProvider>
        <Router>
          <Header />
          <Switch>
            <Route path='/' component={HomePage} exact />
            <Route path='/health' component={HealthCheck} />
            <Route path='/login' component={LoginComponent} />
            <Route path='/create-room' component={RoomComponent} />
            <Route path='/join-room' component={RootComponent} />
          </Switch>
        </Router>
      </RoomProvider>
    </AuthProvider>
  );
}

// const Root = () => {
//     return (
//     )
// }

export default App;
